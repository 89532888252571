import { useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import ExpandView from "components/ExpandView";
import { useDexs, useServiceFee } from "contexts/BankServiceContext";
import useNotification from "hooks/useNotification";
import { putCommas } from "utils";
import { isUserRejected } from "utils/sentry";
import { useLaunch } from "../../../hooks/useLaunch";
import { useEventStatus, useTokenDraft } from "../../../context/TokenContext";
import Header3 from "components/Typography/Header3";
import P from "components/Typography/P";
import Header4 from "components/Typography/Header4";
import Pannel from "components/Widgets/Pannel";
import { Copy, FourStarRound } from "components/Icons";
import Dvider2 from "components/Widgets/Dvider2";
import Tiny from "components/Typography/Tiny";
import Header6 from "components/Typography/Header6";
import Button from "components/Butttons/Button";
import { useActiveWeb3 } from "hooks/useActiveWeb3";
import TransactionModal from "../../TransactionModal";
import Note from "../../../../../components/Widgets/Note";
import TokenDetailReview from "./TokenDetailReview";
import { isChainSupportLock } from "views/launchpad/util";
import TaxReview from "./TaxReview";
import AddOns from "./AddOns";
import LiquidityReview from "./LiquidityReview";
import StarRow from "./StarRow";
import FeeBreakDown from "./FeeBreakDown";
import AntiBotReview from "./AntiBotReview";
import DistributionReview from "./DistributionReview";

interface Props {
  setIsLoading?: any;
}

export default function Review({ setIsLoading }: Props) {
  const [pendingTx, setPendingTx] = useState(false);
  const [isExpaned, setIsExpanedx] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const { setEventEmitted } = useEventStatus();

  const { chain, chainId } = useActiveWeb3();
  const chainCurrencySymbol = useMemo(
    () => chain?.nativeCurrency?.symbol || "ETH",
    [chain]
  );

  const [tradingConf, setTradingConf] = useState<{
    trading_delay: boolean;
    can_enable_trading: boolean;
    blacklist_on: boolean;
  }>({ trading_delay: false, can_enable_trading: false, blacklist_on: false });

  const { draft } = useTokenDraft();
  const serviceFee = useServiceFee();
  const { success, failed } = useNotification();
  const { onLaunch, hash } = useLaunch();

  useEffect(() => {
    if (draft?.uuid) {
      const storageValue = localStorage.getItem(
        `launchpad-${draft?.uuid}-trading-conf`
      );
      if (storageValue) {
        const tradingConfObj = JSON.parse(storageValue);
        setTradingConf({ ...tradingConfObj });
      }
    }
  }, [draft?.uuid]);

  const dexs = useDexs();
  const dex = useMemo(() => {
    return dexs?.find((dx) => dx.name === draft?.pair_provider);
  }, [draft, dexs]);
  const pair_token = useMemo(() => {
    return dex?.currencies?.find((tk) => tk.address === draft?.pair_address);
  }, [dex, draft]);
  // const [setLoading] = useLoader()
  const history = useHistory();

  const inputEl = useRef<HTMLInputElement>(null);
  const onCopy = () => {
    if (inputEl && inputEl.current) {
      navigator.clipboard.writeText(draft?.contract_address);
      toast.success("Copied Address!");
    }
  };

  const handleLaunch = async () => {
    try {
      setIsExpanedx(false);
      setPendingTx(true);
      setShowModal(true);
      setEventEmitted(false);
      const receipt = await onLaunch(
        tradingConf.trading_delay,
        tradingConf.can_enable_trading,
        tradingConf.blacklist_on
      );
      if (!receipt?.status) {
        failed("Something went wrong. Please try again.");
      }
    } catch (err) {
      if (!isUserRejected(err)) {
        console.error(err);
        failed("Something went wrong. Please try again.");
      }
      setShowModal(false);
    } finally {
      setPendingTx(false);
    }
  };

  return (
    <>
      <div className="flex items-center justify-between gap-4 mb-2">
        <div>
          <Header3 text="Review & Launch" />
          <div className="max-w-screen-lg lg:pr-36">
            <P text="Almost there! Review your token details and once finished you’re all good to launch." />
          </div>
        </div>
        <div>
          <CircularProgress
            variant="determinate"
            value={100}
            sx={{
              color: "#FFE68B",
              // background: '#262626',
              borderRadius: 32,
            }}
            size={32}
          />
        </div>
      </div>

      <div className="mb-5">
        <ExpandView
          title="Token Details"
          isExpaned={isExpaned}
          children={<TokenDetailReview />}
        />
        <Dvider2 />

        <ExpandView
          title="Distribution"
          isExpaned={isExpaned}
          children={<DistributionReview />}
        />
        <Dvider2 />

        <ExpandView
          title="Anti-Bot"
          isExpaned={isExpaned}
          children={<AntiBotReview />}
        />
        <Dvider2 />

        <ExpandView
          isExpaned={isExpaned}
          title="Taxes"
          children={<TaxReview />}
        />
        <Dvider2 />
        <ExpandView
          isExpaned={isExpaned}
          title="Liquidity"
          children={<LiquidityReview />}
        />
      </div>

      <Pannel>
        <Header4 text="Fees breakdown" />
        <FeeBreakDown
          tradingConf={tradingConf}
          dex={dex}
          serviceFee={serviceFee}
          chainCurrencySymbol={chainCurrencySymbol}
        />
      </Pannel>
      <Dvider2 />
      <Pannel>
        <Header4 text="Add-ons" />
        <AddOns
          tradingConf={tradingConf}
          setTradingConf={setTradingConf}
          serviceFee={serviceFee}
          chainCurrencySymbol={chainCurrencySymbol}
        />
      </Pannel>
      <Dvider2 />
      <Pannel>
        <Header4 text="Launch" />
        <StarRow label="Deploy your contract and add initial liquidity on deploy." />
        <StarRow
          label={
            draft?.lp_burned ? (
              "Burn initial liquidity"
            ) : isChainSupportLock(chainId) ? (
              <>
                Lock liquidity on Unicrypt for{" "}
                <span className="font-medium dark:text-yellow-1 text-yellow-2">{`${
                  draft?.lp_lock_period
                } ${draft?.lp_locked_for_years ? "years" : "months"}`}</span>
              </>
            ) : (
              "Transfer liquidity to the token deployer"
            )
          }
        />
        <StarRow
          label="By deploying this contract, you are agreeing to BankPad"
          link="https://docs.bankai.app/legal/terms-of-service"
          linkLabel=" Terms of use."
        />
      </Pannel>
      <div className="mt-4">
        {!tradingConf.can_enable_trading && !tradingConf.trading_delay && (
          <Note
            textColor="text-red-1"
            backgroundColor="bg-black-gradient"
            note='<div class="text-sm">IMPORTANT<br/>Your token will be live and tradable immediately after the contract is created.<br/>Select one of the add-ons if you would like to have the contact address before trading is enabled.</div>'
          />
        )}
        <Button label="Launch Token" fullBtn onClick={handleLaunch} outline />
      </div>

      <TransactionModal
        showModal={showModal}
        setShowModal={setShowModal}
        title={<Header6 text="Launching" span={draft?.symbol} />}
        titleAfter={<Header6 text="Launched" span={draft?.symbol} />}
        content={
          <>
            <div className="flex items-center space-x-4">
              {/* <CheckIconBlue /> */}
              <FourStarRound className="shrink-0" />
              <Tiny text={<>Deploy your contract on the Ethereum chain</>} />
            </div>
            <div className="flex items-center space-x-4">
              {/* <CheckIconBlue /> */}
              <FourStarRound className="shrink-0" />
              <Tiny
                text={
                  <>
                    Adding{" "}
                    <span className="font-medium dark:text-yellow-1">{`${putCommas(
                      draft?.pair_amount || 0
                    )} ${(
                      pair_token?.symbol || "ETH"
                    ).toUpperCase()}`}</span>{" "}
                    liquidity
                  </>
                }
              />
            </div>
            <div className="flex items-center space-x-4">
              <FourStarRound className="shrink-0" />
              {/* <CheckIconBlue /> */}
              <Tiny
                text={
                  draft?.lp_burned ? (
                    "Burn liquidity"
                  ) : isChainSupportLock(chainId) ? (
                    <>
                      Lock liquidity on Unicrypt for{" "}
                      <span className="font-medium dark:text-yellow-1 text-yellow-2">{`${
                        draft?.lp_lock_period
                      } ${
                        draft?.lp_locked_for_years ? "years" : "months"
                      }`}</span>
                    </>
                  ) : (
                    "Transfer liquidity to the token deployer"
                  )
                }
              />
            </div>
          </>
        }
        contentAfter={
          <>
            <div className="inline-flex items-center px-4 py-3 mb-4 space-x-2 rounded-md bg-gray-19 dark:bg-dark-3">
              <button className="" onClick={onCopy}>
                <Copy />
              </button>
              <span
                ref={inputEl}
                className="text-sm break-all sm:text-base dark:text-white"
              >
                {draft?.contract_address}
              </span>
            </div>
            <Button
              label="Manage Token"
              endIcon={<i className="fas fa-arrow-right" />}
              outline
              size="md"
              onClick={() => {
                setShowModal(false);
                history.push(`/launchpad/${draft?.uuid}`);
              }}
            />
          </>
        }
        hash={hash}
      />
    </>
  );
}
