import Container from 'components/Layout/Container';
import PrivateSale from 'views/privateSale';

const PrivateSalePage = () => {

  return (
    <>
        <Container>
          <PrivateSale />
        </Container>
    </>

  )
}

export default PrivateSalePage;