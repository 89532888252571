import Container from 'components/Layout/Container';
import Staking from 'views/staking';

const StakingPage = () => {

  return (
    <>
        <Container>
          <Staking />
        </Container>
    </>

  )
}

export default StakingPage;