interface PropsType{
  type ?: string
}
function Dvider1({type}:PropsType ) {

  return (
    <div className="w-10/12 h-px mx-auto my-6 dark:bg-dark-3"></div>
  )

}

export default Dvider1;

