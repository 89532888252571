
function Dvider2() {
  const contentClass = "w-px h-4 ml-8 bg-gray-19 dark:bg-gray-15/30"
  return (
    <div className={contentClass}></div>
  )
}

export default Dvider2;

