import SwitchLockBurn from "components/Butttons/SwitchLockBurn";
import CustomDropdown from "components/dropdown/CustomDropdown";
import TextInput from "components/Form/TextInput";
import Header4 from "components/Typography/Header4";
import Label1 from "components/Typography/Label1";
import P from "components/Typography/P";
import Pannel from "components/Widgets/Pannel";
import Connector from "components/Widgets/Connector";
import { FourStar } from "components/Icons";
import { useTokenDraft } from "../../../context/TokenContext";
import { LockPeriod } from "../../../types";

const LockBurnPanel = () => {
  const { draft, updateDraft } = useTokenDraft();
  const periodCatList = [
    { label: LockPeriod.MONTHS, value: LockPeriod.MONTHS },
    { label: LockPeriod.YEARS, value: LockPeriod.YEARS },
  ];

  return (
    <Pannel>
      <div className="flex items-center justify-between">
        <Header4 text="Lock or burn liquidity" />
        <SwitchLockBurn
          switched={draft?.lp_burned}
          onChange={(val) => {
            const lockProps = val
              ? { lp_lock_period: 1, lp_locked_for_years: false }
              : {};
            updateDraft({
              lp_burned: val,
              ...lockProps,
            });
          }}
        />
      </div>
      <div className="mt-6 flex flex-col">
        {!draft?.lp_burned ? (
          <div>
            <P
              medium
              text="The amount of time you're willing to lock the initial liquidity you're adding for your token."
            />
            <P
              medium
              text="After the initial lock period expires, you are free to withdraw the LP tokens or lock them again."
            />
            <div className="mt-4">
              <Label1 text="LP token lock period" />
              <div className="grid grid-cols-12 mb-2.5">
                <div className="col-span-6 sm:col-span-5 mr-3 sm:mr-0">
                  <TextInput
                    type="number"
                    inputClassName="input-active"
                    label={"LP token lock period"}
                    placeholder={0}
                    min={1}
                    value={draft?.lp_lock_period}
                    onChange={(evt) => {
                      updateDraft({ lp_lock_period: evt.target.value });
                    }}
                    disabled={draft?.lp_burned}
                    size="sm"
                  />
                </div>
                <div className="hidden sm:block">
                  <Connector
                    type="horizontal"
                    icon="right"
                    className="col-span-2"
                    noLine
                  />
                </div>
                <div className="col-span-6 sm:col-span-5">
                  <div className="react-dropdown-wrapper input-active ts-wrapper dark:!bg-dark-3 !bg-gray-19 rounded-md dark:text-white single full select-month">
                    <CustomDropdown
                      options={periodCatList}
                      value={
                        draft?.lp_locked_for_years
                          ? periodCatList[1]
                          : periodCatList[0]
                      }
                      onChange={(value) => {
                        updateDraft({
                          lp_locked_for_years:
                            value == LockPeriod.YEARS ? true : false,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex gap-2">
                <FourStar className="text-yellow-2 dark:text-yellow-1 w-5 h-5" />
                <p className="text-base dark:text-yellow-1">
                  Liquidity will be locked by
                  <span className="text-base text-gray-400 dark:text-gray-4">
                    {" "}
                    <a
                      href="https://app.uncx.network/services/lock-liquidity/"
                      target="_blank"
                    >
                      Unicrypt locking service
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <P
              medium
              text="Your initial LP will be sent to the dead address which makes it ‘burnt’"
            />
            <P
              medium
              text="This means you can no longer access the initial LP tokens after the token is created"
            />
          </div>
        )}
      </div>
    </Pannel>
  );
};

export default LockBurnPanel;
