import Container from 'components/Layout/Container';
import OTCOfferings from 'views/otcOffering';

const OTCOfferingsPage = () => {

  return (
    <>
        <Container>
          <OTCOfferings />
        </Container>
    </>

  )
}

export default OTCOfferingsPage;