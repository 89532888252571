import React, { useContext } from "react";
import { AuthContext } from "contexts/AuthContext";

export function useAuth(): {
  token: string;
  authDone: boolean;
  signedChainId: number;
} {
  const { token, authDone, signedChainId } = useContext(AuthContext);
  return { token, authDone, signedChainId };
}

export function useRefId(): string {
  const { refId } = useContext(AuthContext);
  return refId;
}
