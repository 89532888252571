import { useMemo } from "react";
import ExpanPannel from "components/ExpandView/ExpanPannel";
import ExpanRow from "components/ExpandView/ExpanRow";
import { useDexs } from "contexts/BankServiceContext";
import { useActiveWeb3 } from "hooks/useActiveWeb3";
import { putCommas } from "utils";
import { formatPrice } from "utils/formatBalance";
import { useMarketCap } from "views/launchpad/hooks/useMarketCap";
import { useTokenDraft } from "../../../context/TokenContext";
import { isChainSupportLock } from "../../../util";

const LiquidityReview = () => {
  const { draft } = useTokenDraft();
  const { chain } = useActiveWeb3();
  const dexs = useDexs();
  const dex = useMemo(() => {
    return dexs?.find((dx) => dx.name === draft?.pair_provider);
  }, [draft, dexs]);
  const pair_token = useMemo(() => {
    return dex?.currencies?.find((tk) => tk.address === draft?.pair_address);
  }, [dex, draft]);

  const { marketcap, price } = useMarketCap();

  return (
    <ExpanPannel>
      <ExpanRow
        label={`${
          pair_token ? pair_token.symbol.toUpperCase() : "ETH"
        } TO PAIR WITH TOKEN`}
        value={
          draft?.pair_amount > 0
            ? `${putCommas(draft?.pair_amount)} ${(
                pair_token?.symbol || "ETH"
              ).toUpperCase()}`
            : "-"
        }
      />
      <ExpanRow
        label={`TOKEN TO PAIR WITH ${
          pair_token ? pair_token.symbol.toUpperCase() : "ETH"
        }`}
        value={
          draft?.team_allocation_percent > 0
            ? putCommas(
                draft?.total_supply -
                  (draft?.total_supply * draft?.team_allocation_percent) / 100
              )
            : `${putCommas(draft?.total_supply)} ${draft?.symbol || ""}`
        }
      />
      <ExpanRow
        label="MARKET CAP AT LAUNCH"
        value={`$${formatPrice(marketcap)}`}
      />
      <ExpanRow label="TOKEN PRICE" value={`$${formatPrice(price)}`} />

      <ExpanRow
        label="LP TOKEN LOCK DURATION"
        value={
          draft?.lp_burned
            ? "Burn LP"
            : isChainSupportLock(chain?.id)
            ? `${draft?.lp_lock_period || ""} ${
                draft?.lp_locked_for_years ? "Years" : "Months"
              }`
            : "No Lock"
        }
      />
    </ExpanPannel>
  );
};

export default LiquidityReview;
