
export enum TokenCreationStep {
  DETAILS,
  DISTRIBUTION,
  ANTIBOT,
  TAX,
  LIQUIDITY,
  REVIEW
}

export enum LockPeriod {
  MONTHS = 'Months',
  YEARS = 'Years'
}
