import { useMemo } from "react";
import ExpanPannel from "components/ExpandView/ExpanPannel";
import ExpanRow from "components/ExpandView/ExpanRow";
import { putCommas } from "utils";
import { useTokenDraft } from "../../../context/TokenContext";

const TokenDetailReview = () => {
  const { draft } = useTokenDraft();

  const telegramUrl = useMemo(() => {
    if (!draft) return "-";
    const telegram_url = draft.telegram_url;
    if (!telegram_url) return "-";
    if (telegram_url.startsWith("https://t.me/")) return telegram_url;
    return `https://t.me/${telegram_url}`;
  }, [draft]);

  const twitterUrl = useMemo(() => {
    if (!draft) return "-";
    const twitter_url = draft.twitter_url;
    if (!twitter_url) return "-";
    if (twitter_url.startsWith("https://twitter.com/")) return twitter_url;
    return `https://twitter.com/${twitter_url}`;
  }, [draft]);

  const websiteUrl = useMemo(() => {
    if (!draft) return "-";
    const website_url = draft.website_url;
    if (!website_url) return "-";
    if (website_url.startsWith("https://")) return website_url;
    return `https://${website_url}`;
  }, [draft]);

  return (
    <ExpanPannel>
      <ExpanRow label="TOKEN NAME" value={draft?.name} />
      <ExpanRow label="TOKEN SYMBOL" value={draft?.symbol} />
      <ExpanRow
        label="TOTAL SUPPLY"
        value={`${putCommas(draft?.total_supply)} ${draft?.symbol || ""}`}
      />
      <ExpanRow label="TELEGRAM" value={telegramUrl} />
      <ExpanRow label="TWITTER" value={twitterUrl} />
      <ExpanRow label="WEBSITE" value={websiteUrl} />
    </ExpanPannel>
  );
};

export default TokenDetailReview;
