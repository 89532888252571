// import { useState } from 'react';
import Container from "components/Layout/Container";
import ChainSelect from "views/launchpad/ChainSelect";

const ChainSelectPage = () => {
  return (
    <>
      <Container>
        <ChainSelect />
      </Container>
    </>
  );
};

export default ChainSelectPage;
