import { useMemo } from "react";
import Header7 from "components/Typography/Header7";
import { ETH_ADDRESS } from "config";
import { useActiveWeb3 } from "hooks/useActiveWeb3";
import { BIG_ZERO } from "utils/bigNumber";
import { useTokenDraft } from "../../../context/TokenContext";
import { isChainSupportLock } from "../../../util";
import StarRow from "./StarRow";

const FeeBreakDown = ({
  dex,
  tradingConf,
  serviceFee,
  chainCurrencySymbol,
}) => {
  const { draft } = useTokenDraft();
  const { chain, chainId } = useActiveWeb3();

  const requiredETH = useMemo(() => {
    if (!serviceFee || !draft) return "";
    let amount = BIG_ZERO;
    amount = amount.plus(serviceFee.launch);

    if (draft.pair_address == ETH_ADDRESS)
      amount = amount.plus(draft.pair_amount);
    // lock fee is added for the supported chain
    if (!draft.lp_burned && isChainSupportLock(chainId))
      amount = amount.plus(serviceFee.lock_liquidity);
    if (tradingConf.trading_delay)
      amount = amount.plus(serviceFee.trading_delay);
    if (tradingConf.can_enable_trading)
      amount = amount.plus(serviceFee.enable_trading);
    if (tradingConf.blacklist_on)
      amount = amount.plus(serviceFee.blacklist);
    return amount.toNumber();
  }, [serviceFee, chain, draft, tradingConf]);

  return (
    <div className="space-y-2">
      <StarRow
        label="BankPad service fee"
        value={serviceFee ? `${serviceFee.launch}` : "-"}
        label2={chainCurrencySymbol}
      />
      {isChainSupportLock(chainId) && (
        <StarRow
          label="Unicrypt service fee (for locking LP tokens)"
          value={serviceFee ? `${serviceFee.lock_liquidity}` : "-"}
          label2={chainCurrencySymbol}
        />
      )}
      <StarRow
        label={
          <>
            Funds required for{" "}
            <span className="color1" style={{ textTransform: "capitalize" }}>
              {dex?.display_name}
            </span>{" "}
            liquidity pool
          </>
        }
        value={
          draft?.pair_address == ETH_ADDRESS
            ? `${draft?.pair_amount || 0}`
            : "Not Set"
        }
        label2={draft?.pair_address == ETH_ADDRESS ? chainCurrencySymbol : ""}
      />

      <div className="flex -mt-3 space-x-3">
        <div className="grow">
          <div className="flex items-center justify-between gap-3 mt-1 ml-12">
            <Header7 text={` Total ${chainCurrencySymbol}`} />
            <div className="flex space-x-2">
              <Header7
                text={
                  requiredETH ? `${requiredETH} ${chainCurrencySymbol}` : "-"
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeeBreakDown;
