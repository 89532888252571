import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Header6 from "components/Typography/Header6";
import Tiny from "components/Typography/Tiny";
import { CHAINS, solana } from "config/chains";
import { useActiveWeb3 } from "hooks/useActiveWeb3";
import { useAuth } from "hooks/useAuth";
import useNotification from "hooks/useNotification";
import { useCreate } from "./hooks/useCreate";

const Badge = (props) => {
  return (
    <span
      style={{
        background: props.text === "Popular" ? "#62D1D733" : "#FFE68B44",
        position: "absolute",
        right: 0,
        top: 0,
        padding: "2px 6px",
        borderRadius: 4,
        fontSize: 14,
        color: props.text === "Popular" ? "#62D1D7" : "#FFE68B",
      }}
    >
      {props.text}
    </span>
  );
};
export default function ChainSelect() {
  const [targetChainId, setTargetChainId] = useState();
  const createApiCalled = useRef(false);
  const { onCreate } = useCreate();
  const history = useHistory();
  const { failed } = useNotification();

  const handleCreate = async () => {
    try {
      const result = await onCreate();
      if (result.uuid) {
        history.push(`/launchpad/${result.uuid}/token_details`);
      } else {
        failed(result.message || "Something went wrong");
      }
    } catch (err) {
      failed(err?.toString());
    }
  };

  const { chain, switchNetwork } = useActiveWeb3();
  const { token, authDone, signedChainId } = useAuth();

  const onChooseChain = (chain) => {
    if (switchNetwork && !chain.nonEvm) {
      setTargetChainId(chain.id);
      switchNetwork(chain.id);
    }
  };

  useEffect(() => {
    if (!chain) history.replace("/launchpad");
    else if (
      signedChainId == targetChainId &&
      token &&
      authDone &&
      !createApiCalled.current
    ) {
      createApiCalled.current = true;
      handleCreate();
    }
  }, [chain, targetChainId, token, authDone, signedChainId]);

  return (
    <>
      <div className="px-6 pt-5 pb-11">
        <div className="flex items-cetner justify-center w-full flex-col">
          <Header6 text="Chains/Networks" className="text-center" />
          <Tiny
            subText="Select a network to launch your token on"
            className="mx-auto text-center"
          />
        </div>
        <div className="mt-5 grid grid-cols-1 lg:grid-cols-3 lg gap-3">
          {[...CHAINS, solana]
            // @ts-ignore
            .filter((c) => !c.testnet)
            .map((_chain_, k) => (
              <button
                onClick={() => onChooseChain(_chain_)}
                className="w-full relative flex gap-8 px-5 py-5 bg-white rounded-md cursor-pointer dark:bg-dark-3 hover:dark:bg-dark-4 hover:bg-gray-50 transition-all duration-200 text-lg text-gray500 dark:text-white "
                key={k}
              >
                <div className="relative flex items-center flex-col items-center justify-center w-full gap-3">
                  {_chain_.id === 1 && <Badge text="Popular" />}
                  {
                    // @ts-ignore
                    _chain_.nonEvm && <Badge text="Coming Soon" />
                  }
                  <img
                    src={`/assets/chain/${_chain_.id}.svg`}
                    className="w-18 h-18 rounded-full"
                  />
                  <span>{_chain_.name}</span>
                  <span className="text-sm text-gray-400 dark:text-gray-4">
                    Launch a token on the
                    <span
                      style={{
                        color:
                          _chain_.id === 1
                            ? "#5E6685"
                            : _chain_.id === 8453
                            ? "#0052FF"
                            : "#C34DE5",
                      }}
                    >
                      {" "}
                      {_chain_.name}
                    </span>{" "}
                    chain
                  </span>
                </div>
              </button>
            ))}
        </div>
        <div className="mt-5 py- 5 flex flex-1 gap-3 w-full items-center">
          <Tiny subText="Testnet" />
          <div className="flex flex-1 border-t dark:border-gray-1/40"></div>
        </div>
        <div className="mt-5 grid grid-cols-1 lg:grid-cols-3 lg gap-3">
          {[...CHAINS, solana]
            // @ts-ignore
            .filter((c) => c.testnet)
            .map((_chain_, k) => (
              <button
                onClick={() => onChooseChain(_chain_)}
                className="w-full relative flex gap-8 px-5 py-5 bg-white rounded-md cursor-pointer dark:bg-dark-3 hover:dark:bg-dark-4 hover:bg-gray-50 transition-all duration-200 text-lg text-gray500 dark:text-white "
                key={k}
              >
                <div className="relative flex items-center flex-col items-center justify-center w-full gap-3">
                  <img
                    src={`/assets/chain/${_chain_.id}.svg`}
                    className="w-18 h-18 rounded-full"
                  />
                  <span>{_chain_.name}</span>
                  <span className="text-sm text-gray-400 dark:text-gray-4">
                    Launch a token on the
                    <span
                      style={{
                        color: "#5E6685",
                      }}
                    >
                      {" "}
                      {_chain_.name}
                    </span>{" "}
                    chain
                  </span>
                </div>
              </button>
            ))}
        </div>
      </div>
    </>
  );
}
