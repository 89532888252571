import { useMemo } from "react";
import Switch from "components/Butttons/Switch";
import Tiny from "components/Typography/Tiny";
import TinyRed from "components/Typography/TinyRed";
import { useTokenDraft } from "../../../context/TokenContext";

const AddOns = ({
  tradingConf,
  setTradingConf,
  serviceFee,
  chainCurrencySymbol,
}) => {
  const { draft } = useTokenDraft();
  const handleSwitch1 = (checked) => {
    const trading_delay = checked;
    const can_enable_trading =
      trading_delay && tradingConf.can_enable_trading
        ? false
        : tradingConf.can_enable_trading;
    setTradingConf({ ...tradingConf, trading_delay, can_enable_trading });
    localStorage.setItem(
      `launchpad-${draft?.uuid}-trading-conf`,
      JSON.stringify({ ...tradingConf, trading_delay, can_enable_trading })
    );
  };

  const handleSwitch2 = (checked) => {
    const can_enable_trading = checked;
    const trading_delay =
      can_enable_trading && tradingConf.trading_delay
        ? false
        : tradingConf.trading_delay;
    setTradingConf({ ...tradingConf, trading_delay, can_enable_trading });
    localStorage.setItem(
      `launchpad-${draft?.uuid}-trading-conf`,
      JSON.stringify({ ...tradingConf, trading_delay, can_enable_trading })
    );
  };

  const handleSwitch3 = (checked) => {
    setTradingConf({ ...tradingConf, blacklist_on: checked });
    localStorage.setItem(
      `launchpad-${draft?.uuid}-trading-conf`,
      JSON.stringify({ ...tradingConf, blacklist_on: checked })
    );
  };

  return (
    <div className="space-y-2">
      <div>
        <div className="flex mb-3 space-x-3">
          <div className="relative flex pb-9">
            <div className="w-2.5 h-0.5 bg-gray-19 dark:bg-dark-4 mt-3 absolute left-0 -translate-x-full"></div>
            <div className="absolute w-0.5 bg-gray-19 dark:bg-dark-4 h-full -left-2.5 mt-3"></div>
            <div className="flex mt-1">
              <Switch
                onChange={handleSwitch1}
                switched={tradingConf.trading_delay}
                noBorder
                id="delay-1"
              />
            </div>
          </div>

          <div className="grow">
            <div className="flex items-center justify-between mb-1\.5">
              <Tiny text="Delay trading" />
              <div className="flex items-center gap-1">
                <div className="text-base font-medium text-right dark:text-white shrink-0 whitespace-nowrap">
                  {serviceFee ? `${serviceFee.trading_delay}` : "-"}
                  <span className="ml-2 text-sm font-bold text-gray-400 dark:text-gray-4">
                    {chainCurrencySymbol}
                  </span>
                </div>
              </div>
            </div>
            <div className="w-9/12">
              <Tiny subText="Delay trading for 60 seconds so you can have the contract address before trading is enabled" />
            </div>
          </div>
        </div>

        <div className="flex -mt-3 space-x-3">
          <div className="relative flex">
            <div className="w-2.5 h-0.5 bg-gray-19 dark:bg-dark-4 mt-3 absolute left-0 -translate-x-full"></div>
            <div className="flex mt-1">
              <Switch
                onChange={handleSwitch2}
                switched={tradingConf.can_enable_trading}
                noBorder
                id="delay-2"
              />
            </div>
          </div>

          <div className="grow">
            <div className="flex items-center justify-between mb-1\.5">
              <Tiny text="Manually enable trading" />
              <div className="flex items-center gap-1">
                <div className="text-base font-medium text-right dark:text-white shrink-0 whitespace-nowrap">
                  {serviceFee ? `${serviceFee.enable_trading}` : "-"}
                  <span className="ml-2 text-sm font-bold text-gray-400 dark:text-gray-4">
                    {chainCurrencySymbol}
                  </span>
                </div>
              </div>
            </div>
            <div className="w-9/12">
              <Tiny subText="Deploy the contract, add LP, but enable trading and launch the token within the next 7 days" />
              <TinyRed
                text="Trading would be automatically enabled after 7 days from the time of contract creation if not enabled by the owner"
                noBorder={true}
              />
            </div>
          </div>
        </div>

        <div className="flex mt-3 space-x-3">
          <div className="relative flex pb-9">
            <div className="flex mt-1">
              <Switch
                onChange={handleSwitch3}
                switched={tradingConf.blacklist_on}
                noBorder
                id="blacklist"
              />
            </div>
          </div>

          <div className="grow">
            <div className="flex items-center justify-between mb-1\.5">
              <Tiny text="Enable blacklist" />
              <div className="flex items-center gap-1">
                <div className="text-base font-medium text-right dark:text-white shrink-0 whitespace-nowrap">
                  {serviceFee ? `${serviceFee.blacklist}` : "-"}
                  <span className="ml-2 text-sm font-bold text-gray-400 dark:text-gray-4">
                    {chainCurrencySymbol}
                  </span>
                </div>
              </div>
            </div>
            <div className="w-9/12">
              <Tiny subText="Enable blacklist in the token contract to prevent exploit from the spam accounts" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddOns;
