import { useMemo } from "react";
import ExpanPannel from "components/ExpandView/ExpanPannel";
import ExpanRow from "components/ExpandView/ExpanRow";
import { putCommas } from "utils";
import { useTokenDraft } from "../../../context/TokenContext";

const AntiBotReview = () => {
  const { draft } = useTokenDraft();

  return (
    <ExpanPannel>
      <ExpanRow
        label="MAX TOKENS PER TRANSACTION"
        value={
          draft?.max_tokens_per_transaction_percent > 0
            ? `${draft?.max_tokens_per_transaction_percent} % = ${putCommas(
                (draft?.total_supply *
                  draft?.max_tokens_per_transaction_percent) /
                  100
              )} ${draft?.symbol || ""}`
            : "-"
        }
      />
      <ExpanRow
        label="MAX TOKENS PER WALLET"
        value={
          draft?.max_tokens_per_wallet_percent > 0
            ? `${draft?.max_tokens_per_wallet_percent} % = ${putCommas(
                (draft?.total_supply * draft?.max_tokens_per_wallet_percent) /
                  100
              )} ${draft?.symbol || ""}`
            : "-"
        }
      />
    </ExpanPannel>
  );
};

export default AntiBotReview;
