import Container from 'components/Layout/Container';
import Locker from 'views/locker';

const LockerPage = () => {

  return (
    <>
        <Container>
          <Locker />
        </Container>
    </>

  )
}

export default LockerPage;