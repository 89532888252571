export default {
  token_factory: {
    1: "0x4242b8019e000A33251Da56cbeF35695ec7E4f55",
    5: "0x76797dacbB222565752A73528f99d2dda959567d",
    11155111: "0x53CC734b8b99A1899f4fd5D269E51dC05589F843",
    8453: "0x7461E1d7a74b8C4E2bcc65F0E086922B9dE8a9B6",
  },
  migration: {
    1: "0xAc4efA4F2dd3757727D563Ef554cC4DE2B28dEaB",
    5: "0x20c16e89449850Cd8d1bf052e6553CBC068a1fd6",
  },
  multiCall: {
    1: "0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696",
    5: "0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696",
    8453: "0xca11bde05977b3631167028862be2a173976ca11",
    11155111: "0xca11bde05977b3631167028862be2a173976ca11",
  },
};
