import { useMemo } from "react";
import { getAddress } from "viem";
import ExpanPannel from "components/ExpandView/ExpanPannel";
import ExpanRow from "components/ExpandView/ExpanRow";
import { useTokenDraft } from "../../../context/TokenContext";

const TaxReview = () => {
  const { draft } = useTokenDraft();

  return (
    <ExpanPannel>
      <ExpanRow
        label="BUY TAX"
        value={
          draft?.buy_tax > 0
            ? `${draft?.buy_tax}% + ${draft?.buy_tax / 10}% BankPad tax = ${
                (draft?.buy_tax * 11) / 10
              }% total buy tax`
            : "-"
        }
      />

      <ExpanRow
        label="SELL TAX"
        value={
          draft?.sell_tax > 0
            ? `${draft?.sell_tax}% + ${draft?.sell_tax / 10}% BankPad tax = ${
                (draft?.sell_tax * 11) / 10
              }% total sell tax`
            : "-"
        }
      />
      <ExpanRow
        label="TAX PAYOUT ADDRESS"
        value={
          draft?.tax_payout_address
            ? getAddress(draft?.tax_payout_address)
            : "-"
        }
      />
      <ExpanRow
        label="TREASURY/STAKING TAX"
        value={
          draft?.treasury_tax > 0
            ? `${draft?.treasury_tax}% + ${
                draft?.treasury_tax / 10
              }% Bankpad tax = ${
                (draft?.treasury_tax * 11) / 10
              }% total treasury tax`
            : "-"
        }
      />
      <ExpanRow
        label="TREASURY PAYOUT ADDRESS"
        value={
          draft?.treasury_payout_address
            ? getAddress(draft?.treasury_payout_address)
            : "-"
        }
      />
    </ExpanPannel>
  );
};

export default TaxReview;
