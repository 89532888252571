import Container from 'components/Layout/Container';
import Airdrop from 'views/airdrop';

const AirdropPage = () => {

  return (
    <>
        <Container>
          <Airdrop />
        </Container>
    </>

  )
}

export default AirdropPage;