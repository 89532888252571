import { FourStarRound } from "components/Icons";

interface RowProps {
  label?: any;
  value?: any;
  span?: string;
  link?: string;
  linkLabel?: string;
  label2?: string;
}

export default function StarRow({
  label,
  value,
  span,
  link,
  linkLabel,
  label2,
}: RowProps) {
  return (
    <div className="flex items-center justify-between gap-3">
      <div className="flex items-center gap-4">
        <FourStarRound className="shrink-0" />
        <p className="text-sm dark:text-white">
          {label}
          {span && (
            <span className="font-medium text-yellow-2 dark:text-yellow-1">
              {span}
            </span>
          )}
          {link && (
            <a
              href={link}
              target="_blank"
              className="font-medium text-gray-400 dark:text-gray-4"
            >
              {linkLabel}
            </a>
          )}
        </p>
      </div>

      {value && (
        <div className="flex items-center gap-1">
          {value !== "Not sure"}
          <div className="text-base font-medium text-right dark:text-white whitespace-nowrap">
            {value}
            <span className="ml-2 text-sm font-bold text-gray-400 dark:text-gray-4">
              {label2}
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
