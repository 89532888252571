import SwitchOnOff from "components/Butttons/SwitchOnOff";
import CustomDropdown from "components/dropdown/CustomDropdown";
import TextInput from "components/Form/TextInput";
import Header4 from "components/Typography/Header4";
import Label1 from "components/Typography/Label1";
import P from "components/Typography/P";
import Pannel from "components/Widgets/Pannel";
import Connector from "components/Widgets/Connector";
import { FourStar } from "components/Icons";
import { useTokenDraft } from "../../../context/TokenContext";
import { LockPeriod } from "../../../types";

const BurnDistPanel = () => {
  const { draft, updateDraft } = useTokenDraft();
  const periodCatList = [
    { label: LockPeriod.MONTHS, value: LockPeriod.MONTHS },
    { label: LockPeriod.YEARS, value: LockPeriod.YEARS },
  ];

  return (
    <Pannel>
      <div className="flex items-center justify-between">
        <Header4 text="Burn or hold liquidity" />
        <SwitchOnOff
          switched={draft?.lp_burned}
          onChange={(val) => {
            const lockProps = val
              ? { lp_lock_period: 1, lp_locked_for_years: false }
              : {};
            updateDraft({
              lp_burned: val,
              ...lockProps,
            });
          }}
        />
      </div>
      <div className="mt-6 flex flex-col">
        {draft?.lp_burned ? (
          <div>
            <P
              medium
              text="Your initial LP will be sent to the dead address which makes it ‘burnt’"
            />
            <P
              medium
              text="This means you can no longer access the initial LP tokens after the token is created"
            />
          </div>
        ) : (
          <div>
            <P medium text="Your initial LP will be sent to you" />
          </div>
        )}
      </div>
    </Pannel>
  );
};

export default BurnDistPanel;
