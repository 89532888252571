import { useMemo } from "react";
import { getAddress } from "viem";
import ExpanPannel from "components/ExpandView/ExpanPannel";
import ExpanRow from "components/ExpandView/ExpanRow";
import { putCommas } from "utils";
import { useTokenDraft } from "../../../context/TokenContext";

const DistributionReview = () => {
  const { draft } = useTokenDraft();

  return (
    <ExpanPannel>
      <ExpanRow
        label="TEAM SUPPLY ALLOCATION"
        value={
          draft?.team_allocation_percent > 0
            ? `${draft?.team_allocation_percent} % = ${putCommas(
                (draft?.total_supply * draft?.team_allocation_percent) / 100
              )} ${draft?.symbol}`
            : "-"
        }
      />
      <ExpanRow
        label="TEAM SUPPLY ADDRESS"
        value={
          draft?.team_allocation_address
            ? getAddress(draft?.team_allocation_address)
            : "-"
        }
      />
    </ExpanPannel>
  );
};

export default DistributionReview;
